import React, { Fragment } from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import slug from "slug"

/* Import Global Component(s) */
import SEO from "~components/seo/seo"

/* Import Global Animation(s) */
import pageTransitionAnimation from "~animations/pageTransition"

/* Import Local Style(s) */
import "./page.scss"

const Page = ({
  title,
  children,
  className = null,
  location,
  style = null,
}) => (
  <Fragment>
    <SEO title={title} />
    <motion.main
      className={`page page--${slug(title)} ${className ? className : ""}`}
      style={style}
      variants={pageTransitionAnimation.variants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.main>
  </Fragment>
)

Page.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Page
