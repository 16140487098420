import React, { Fragment } from "react"
import Helmet from "react-helmet"

const SEO = ({ title }) => (
  <Fragment>
    <Helmet>
      {/* General tags */}
      <title>{title} | Untitled Art </title>
      <meta
        name="description"
        content="Untitled Art is an international, curated art fair that focuses on curatorial balance and integrity across all disciplines of contemporary art."
      />
      <meta name="image" content="public/logo.png" />
      {/* OpenGraph tags */}
      <meta property="og:url" content="https://untitledartfairs.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Untitled Art" />
      <meta
        property="og:description"
        content="Untitled Art is an international, curated art fair that focuses on curatorial balance and integrity across all disciplines of contemporary art."
      />
      <meta property="og:image" content="public/logo.png" />
    </Helmet>
  </Fragment>
)

export default SEO
